import https from '@/router/https'

export function getCoupon(param) {
    return https.fetchGet('/mallapp/couponinfo/selectSpuCoupon', param);
}
export function postCoupon(param) {
    return https.fetchPost('/mallapp/couponuser', param);
}
// 优惠券列表
export function getCouponEmploy(param) {
    return https.fetchGet('/mallapp/couponuser/page', param);
}
// 领取优惠卷
export function fetchCoupon(param) {
    return https.fetchPost('/mallapp/couponuser', param);
}
