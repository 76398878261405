<template>
  <div>
    <!-- 导航 -->
    <div
      style="
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        right: 0px;
        z-index: 10;
      "
    >
      <van-nav-bar
        title="确认订单"
        left-text
        left-arrow
        @click-left="$router.back(-1)"
      >
        <template #right></template>
      </van-nav-bar>
    </div>
    <!-- 导航 -->
    <div style="margin: 50px 0; padding: 0 0 50px">
      <!-- 收货地址 -->
      <div @click="$router.push('/shippingAddress')">
        <div
          v-if="address == ''"
          style="
            display: flex;
            padding: 15px 15px;
            align-items: center;
            background-color: #ffffff;
            flex-direction: column;
          "
        >
          <div>
            <van-icon size="20px" name="add-o" />
          </div>
          <div>点击添加收货地址</div>
        </div>
        <div v-if="address != ''">
          <van-cell is-link center>
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
              <span class="title">{{ address.userName }}</span>
              <span class="title" style="margin-left: 6px">{{
                address.telNum
              }}</span>
              <van-tag
                color="#FF0038"
                text-color="#ffffff"
                v-show="address.isDefault == 1"
                style="margin-left: 8px"
                >默认</van-tag
              >
              <!-- <van-tag color="#3E85FF" text-color="#ffffff">家</van-tag> -->
            </template>
            <!-- 使用 label 插槽来自定义描述 -->
            <template #label>
              <span class="label">{{
                address.provinceName +
                address.cityName +
                address.countyName +
                address.detailInfo
              }}</span>
            </template>
          </van-cell>
        </div>
      </div>
      <!-- 收货地址 -->

      <div style="background-color: #f1f1f1; height: 15px"></div>
      <!-- 商品价格和详情  v-for="(item,index) in form"
        :key="index" -->
      <div style="padding: 0 15px; background-color: #ffffff">
        <!-- <div v-if="index==0" style="padding-top:15px;">{{item.shopName}}</div> -->
        <div
          style="display: flex; justify-content: space-between; padding: 15px 0"
        >
          <div style="display: flex">
            <div>
              <van-image
                style="
                  width: 80px;
                  height: 100%;
                  margin-right: 10px;
                  border-radius: 0.266rem;
                "
                fit="cover"
                :src="form.picUrls[0] ? form.picUrls[0] : null"
              />
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              "
            >
              <div>{{ form.name ? form.name : "" }}</div>
              <div style="color: #b2b2b2">
                <span>规格：</span>
                <span>{{ speValue ? speValue : "统一规格" }}</span>
              </div>
              <div style="color: #b2b2b2">
                <span>运费：</span>
                <span>￥{{ this.freightPrice ? this.freightPrice : "0" }}</span>
              </div>
            </div>
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              justify-content: space-between;
            "
          >
            <div style="color: #a80000; font-weight: 500; font-size: 18px">
              <span style="font-size: 14px">￥</span>
              <span style="font-size: 18px">{{ priceNow }}</span>
            </div>
            <div>*{{ totalNum }}</div>
          </div>
        </div>
      </div>
      <div style="padding: 0 15px 15px; background-color: #ffffff">
        <!-- <div style="margin-bottom:15px;">
          <span style="margin-right:20px;">运费</span>
          <span>    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{$route.query.cash}}元</span>
        </div> -->
        <div style="margin-bottom: 15px">
          <span style="margin-right: 20px">配送方式</span>
          <span>普通快递</span>
        </div>
        <div>
          <div style="margin-right: 20px">商品备注</div>
          <van-field
            v-model="remark"
            rows="1"
            autosize
            type="textarea"
            placeholder="选填 请先和商家协商一致"
            style="padding: 10px 0"
          />
        </div>
        <div style="margin-top: 15px">
          <div style="text-align: right">
            <span>共{{ totalNum }}件商品</span>
            <span>小计：</span>
            <span style="color: #a80000; font-size: 14px; font-weight: 500"
              >￥</span
            >
            <span style="color: #a80000; font-size: 18px; font-weight: 500">{{
              (priceNow * totalNum).toFixed(2)
            }}</span>
          </div>
        </div>
      </div>
      <!-- 商品价格和详情 -->
      <div style="background-color: #f1f1f1; height: 15px"></div>
      <van-cell
        title="商品总额"
        :value="'￥' + (priceNow * totalNum).toFixed(2)"
        value-class="priceStyle"
      ></van-cell>
      <van-cell
        title="运费"
        :value="'￥' + this.freightPrice"
        value-class="priceStyle"
      ></van-cell>

      <van-cell
        title="优惠券"
        @click="getCouponList"
        :value="isHaveCoupon"
        value-class="priceStyle"
      ></van-cell>

      <!-- 优惠券列表 -->
      <van-popup
        v-model="popupShow"
        round
        position="bottom"
        style="padding-top: 4px; min-height: 300px; max-height: 80%"
      >
        <div class="coupon-content">
          <h3 style="margin-left: 47%; margin-top: 12px">优惠券</h3>

          <div class="lists">
            <div class="list" v-for="item in couponUserList" :key="item.id">
              <div class="list-left">
                <div class="left-top">
                  <span v-if="item.type == 2"
                    >￥<span style="font-size: 16px; margin: 0 4px"
                      >{{ item.discount }}折劵</span
                    ></span
                  >
                  <span v-else
                    >￥<span style="font-size: 16px; margin: 0 4px">{{
                      item.reduceAmount
                    }}</span></span
                  >
                  <span>订单满{{ item.premiseAmount }}元使用</span>
                </div>
                <div
                  class="left-bottom"
                  :style="{ visibility: item.type == 2 ? '' : 'hidden' }"
                >
                  <span>使用日期: </span>
                  <span>{{ item.validBeginTime.substr(0, 11) }}</span
                  >至&nbsp;<span>{{ item.validEndTime.substr(0, 10) }}</span>
                </div>
              </div>
              <div class="list-right" @click="toUsePay(item)">
                <span>使用优惠券</span>
              </div>
            </div>
          </div>
        </div>
      </van-popup>

      <van-radio-group
        v-model="radio"
        style="
          padding-bottom: constant(safe-area-inset-bottom);
          padding-bottom: env(safe-area-inset-bottom);
        "
      >
        <van-cell-group>
          <van-cell clickable @click="radio = '1'">
            <template #title>
              <img class="img" src="../../assets/img/alipay-icon.png" alt />
              <span class="custom-title">支付宝</span>
            </template>
            <template #right-icon>
              <van-radio name="1" />
            </template>
          </van-cell>
          <!-- <van-cell clickable @click="radio = '2'">
            <template #title>
              <img class="img" src="../../assets/img/weChat-icon.png" alt />
              <span class="custom-title">微信</span>
            </template>
            <template #right-icon>
              <van-radio name="2" />
            </template>
          </van-cell> -->
        </van-cell-group>
      </van-radio-group>
    </div>

    <!-- 底部 -->
    <div class="bots" style="position: fixed; bottom: 0px">
      <van-submit-bar
        :price="submitTotalPrice"
        button-text="提交订单"
        @submit="onSubmit"
      />
    </div>
    <!-- 底部 -->
  </div>
</template>
<script>
import { getPage } from "@/api/shoppingAddress/shoppingAddress";
import {
  submitOrder,
  submitAlipay,
  submitWxpay,
} from "@/api/sureOrder/sureOrder";
import { Toast } from "vant";
import { geItemDetail } from "@/api/itemDetail";
import { getCouponEmploy } from "@/api/coupon/coupon";
let backAddress = "";
export default {
  data() {
    return {
      form: {},
      remark: "",
      address: "",
      radio: "1",
      checked: true,
      totalPrice: 0,
      totalNum: 0,
      couponUserList: [],
      namer: "",
      name: 0,
      value: "",
      popupShow: false,
      total: 0,
      fore: {
        current: 1,
        size: 5,
      },
      spuIds: null,
      channelCid: null,
      shopId: null,
      type: null,

      couponUserId: null,
      // num: null, //购买数量
      speValue: "",
      isHaveCoupon: "",
      submitTotalPrice: 0, // 提交订单总价格
      couponUserId2: null,
      discount2: null,
      reduceAmount2: null,
      freightPrice: null, //运费
      priceNow: 0, // 单格
    };
  },
  // components: { couponItem },
  beforeRouteEnter(to, from, next) {
    if (from.name === "ShippingAddress") {
      if (from.params.address) backAddress = from.params.address;
    } else {
      backAddress = "";
    }
    next();
  },

  created() {
    this.priceNow = this.$route.query.priceNow;
    this.geItemDetail(this.$route.query.id);

    // this.spuIds = this.form[0].skus.spuId;
    if (backAddress == "" || !backAddress) {
      if (
        this.$route.query.address != null &&
        this.$route.query.address != ""
      ) {
        if (JSON.parse(this.$route.query.address) != "") {
          this.address = JSON.parse(this.$route.query.address);
        } else {
          this.getPage();
        }
      } else {
        this.getPage();
      }
    } else {
      this.address = backAddress;
    }
    if (this.$route.query.channelCid) {
      this.channelCid = this.$route.query.channelCid;
    }
    if (this.$route.query.shopId) {
      this.shopId = this.$route.query.shopId;
    }
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
    // Toast(this.$store.state.session)
  },
  mounted() {
    window.closePage = this.closePage;
  },
  methods: {
    // 使用优惠卷购买
    toUsePay(item) {
      this.couponUserId2 = item.id;
      // 打折type为2
      if (item.type == 2) {
        this.isHaveCoupon = `${item.discount}折`;
        this.discount2 = item.discount;
        let total =
          (this.totalNum * this.priceNow * item.discount) / 10 +
          this.freightPrice;
        this.submitTotalPrice = total * 100;
      }
      // 满减
      if (item.type == 1) {
        this.isHaveCoupon = `-${item.reduceAmount}元`;
        this.reduceAmount2 = item.reduceAmount;
        let total =
          this.totalNum * this.priceNow + this.freightPrice - item.reduceAmount;
        this.submitTotalPrice = total * 100;
      }
      this.popupShow = false;
      Toast("使用优惠券成功");
    },
    popuptype() {
      this.popupShow = true;
    },

    getPage() {
      getPage({ current: 1, size: 10 }).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data.records.length == 0) {
            this.address = "";
          } else {
            this.address = res.data.data.records[0];
          }
        }
      });
    },
    getCoupontype() {
      // 优惠券列表
      let param = { shopId: this.form.shopId };
      getCouponEmploy(param).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data.length) {
            const totalPrice = (this.form.priceDown * this.totalNum).toFixed(2);
            const couponUserList = res.data.data[0].couponUserList;
            const new_couponUserList = couponUserList.filter(
              (x) => parseFloat(totalPrice) >= x.premiseAmount
            );
            this.couponUserList = JSON.parse(
              JSON.stringify(new_couponUserList)
            );
            this.total = res.data.data.total;
            this.isHaveCoupon = "使用优惠劵";
          } else {
            this.isHaveCoupon = "无可使用优惠劵";
          }
        }
      });
    },

    // 弹出优惠卷列表
    getCouponList() {
      if (this.couponUserList.length) {
        this.popupShow = true;
      }
    },

    // 订单详情
    geItemDetail(id) {
      geItemDetail(id).then((res) => {
        if (res.data.code === 0) {
          this.form = JSON.parse(JSON.stringify(res.data.data));
          this.computedFreight();
          this.getCoupontype();
        }
      });
    },

    computedFreight() {
      this.speValue = this.$route.query.value;
      const volume = this.$route.query.volume;
      const weight = this.$route.query.weight;
      this.totalNum = this.$route.query.num;

      // 算运费
      const data = this.form.freightTemplat;
      const chargeType = this.form.freightTemplat.chargeType;
      if (chargeType == "1") {
        const shopQuantity = this.$route.query.num;
        if (
          shopQuantity < data.firstNum ||
          (data.firstNum == 0 && data.continueNum == 0)
        ) {
          this.freightPrice = 0;
        } else if (shopQuantity == data.firstNum) {
          this.freightPrice = data.firstFreight;
        } else if (
          shopQuantity > data.firstNum &&
          shopQuantity - data.firstNum < data.continueNum
        ) {
          this.freightPrice = data.firstFreight;
        } else if (
          shopQuantity > data.firstNum &&
          shopQuantity - data.firstNum >= data.continueNum
        ) {
          const xvJianShuo = shopQuantity - data.firstNum;
          const continueNum = data.continueNum;
          this.freightPrice =
            Math.floor(xvJianShuo / continueNum) * data.continueFreight +
            data.firstFreight;
        }
      } else if (chargeType == "2" || chargeType == "3") {
        const goodsQuantity =
          Number(this.totalNum) * Number(chargeType == "2" ? weight : volume);
        if (
          goodsQuantity < data.firstNum ||
          (data.firstNum == 0 && data.continueNum == 0)
        ) {
          this.freightPrice = 0;
        } else if (goodsQuantity == data.firstNum) {
          this.freightPrice = data.firstFreight;
        } else if (
          goodsQuantity > data.firstNum &&
          goodsQuantity - data.firstNum < data.continueNum
        ) {
          this.freightPrice = data.firstFreight;
        } else if (
          goodsQuantity > data.firstNum &&
          goodsQuantity - data.firstNum >= data.continueNum
        ) {
          const xvJianShuo = goodsQuantity - data.firstNum;
          const continueNum = data.continueNum;
          this.freightPrice =
            Math.floor(xvJianShuo / continueNum) * data.continueFreight +
            data.firstFreight;
        }
      } else if (chargeType == "") {
        this.freightPrice = 0;
      }
      this.submitTotalPrice =
        (this.totalNum * this.priceNow + this.freightPrice) * 100;
    },

    onSubmit() {
      if (this.address == "") {
        Toast("请选择地址");
        return;
      }
      let appType = "";
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //androi
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        appType = "ios";
      }
      if (isAndroid) {
        appType = "Android";
      }
      // 后面修改的
      let paymentType = null;
      if (this.radio == "1") {
        paymentType = "2";
      } else {
        paymentType = "1";
      }
      //
      let paymentCouponPrice = null;
      if (this.discount2) {
        paymentCouponPrice = this.discount2;
      }
      if (this.reduceAmount2) {
        paymentCouponPrice = this.reduceAmount2;
      }
      let skus = [
        {
          spuId: this.form.id,
          skuId: this.$route.query.skuId,
          quantity: this.$route.query.num,
          paymentPrice: this.submitTotalPrice / 100,
          freightPrice: this.freightPrice,
          paymentPoints: null,
          paymentCouponPrice: paymentCouponPrice,
          paymentPointsPrice: null,
          couponUserId: this.couponUserId2,
        },
      ];
      let form = {
        paymentWay: "2",
        deliveryWay: "1",
        paymentType: paymentType,
        userMessage: this.remark, //备注
        appType: appType,
        appId: null,
        userAddressId: this.address.id,
        orderType: "0",
        relationId: null, //营销id
        visitTime: null,
        skus: skus, //
        type: this.type, //原生传过来的
        userId: this.$store.state.session,
      };
      let arr = [];
      submitOrder(form).then((res) => {
        if (res.data.code === 0) {
          Toast("下单成功");
          this.getCoupontype();
          this.submitTotalPrice =
          (this.totalNum * this.priceNow + this.freightPrice) * 100;
          this.couponUserId2 = null // 下单成功后清楚优惠券id
          try {
            res.data.data.forEach((item) => {
              arr.push(item.id);
            });
          } catch {
            //
          }
        } else {
          return Toast(res.data.message);
        }
        if (res.data.code != 0) {
          Toast(res.data.message);
          return;
        } else {
          // Toast("提交订单成功");
        }
        if (this.radio == "1") {
          this.submitAlipay(arr);
        } else {
          this.submitWxpay(arr);
        }
      });
    },

    closePage() {
      this.$goBack();
    },
    //原生支付宝支付
    submitAlipay(form) {
      submitAlipay({ ids: form, tradeType: "APP" }).then((res) => {
        // 后面改了等于0
        if (res.data.data === null) {
          this.$goBack();
          return;
        }
        if (res.data.code === 0) {
          // Toast.success("下单成功");
          try {
            window.webkit.messageHandlers.alipay.postMessage(res.data.data);
          } catch {
            window.android.alipay(res.data.data);
          }
        }
      });
    },
    //原生微信支付
    submitWxpay(id) {
      submitWxpay({ id: id.join(), tradeType: "APP" }).then((res) => {
        console.log(res, '葛先波')
        if (res.data.data === null) {
          this.$goBack();
          return;
        }
        try {
          window.webkit.messageHandlers.wechatPay.postMessage(
            JSON.stringify(res.data.data)
          );
        } catch {
          window.android.wxpay(
            res.data.data.appId,
            res.data.data.partnerId,
            res.data.data.prepayId,
            res.data.data.nonceStr,
            res.data.data.timeStamp,
            res.data.data.packageValue,
            res.data.data.sign
          );
        }
      });
    },
  },
  computed: {
    freight() {
      if (this.form.freightPrice) {
        return this.form.freightPrice;
      } else {
        return 0;
      }
    },
  },
};
</script>
<style lang="less">
.coupon-content {
  .lists {
    margin: 10px;
    .list {
      font-size: 12px;
      border-radius: 6px;
      color: #fff;
      // linear-gradient(to right, #EAE5C9, #6CC6CB);
      background: linear-gradient(to left, #e82063, #f96485);
      display: flex;
      border-radius: 8px;
      height: 70px;
      margin-bottom: 10px;
      .list-left {
        margin-left: 8px;
        flex: 1;
        display: flex;
        flex-direction: column;
        div {
          margin-top: 10px;
        }
      }
      .list-right {
        margin-right: 3px;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.sureorder-one {
  position: absolute;
  font-size: 10px;
  bottom: 2px;
  /* padding: 0 0 0 1px; */
  color: #fff;
  display: block;
}
.name-one {
  /* border: 1px solid #fff; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px 0 0 60px;
}
.sureorderPell {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
  background-color: #fff;
  font-size: 14px;
  color: #666;
  padding: 10px 16px 0 16px;
  line-height: 30px;
}
.receive {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffc656;
  font-size: 13px;
  color: #c17412;
}
.coupon-oner {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  text-align: center;

  color: #fff;
  /* border: 1px solid red; */
  padding: 10px 16px 0 10px;
}
.sureorderDiscount-listNumber {
  /* padding-top: 30px; */
  /* padding-right: 5px; */
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.funtion {
  display: flex;
}
.sureorderPell-one {
  display: flex;
  /* border: 1px solid red; */
  justify-content: space-between;
}
.sureorderDiscount {
  /* width: 343px; */
  height: 104px;
  /* border: 1px solid red; */
  margin: 10px 16px 0 16px;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.orange-img {
  background-image: url("../../assets/lingjuanzhongxin.png");
}
.blue-img {
  background-image: url("../../assets/lingjuanzhongxin2.png");
}
.sureorderDiscount-Number {
  display: flex;
  justify-content: space-between;
  /* padding: 0 10px 0 10px; */
  /* :0 16px 0 16px; */
}
.sureorderDiscount-one {
  font-size: 17px;
}
/* .sureorderDiscount-two{
  background-color: red;
  border-radius: 50px;
  height: 30px;
  line-height: 30px;
  border: 1px solid red;
  margin: 400px 16px 0 16px;
  text-align: center;
} */
/* .sureorderDiscount-name { */
/* display: flex; */
/* flex-direction: column; */
/* justify-content: space-around; */
/* } */
.van-nav-bar .van-icon {
  color: black;
}
.bots {
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  display: flex;
  background-color: #ffffff;
  justify-content: space-evenly;
  z-index: 10;
}
.img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  vertical-align: middle;
}

.priceStyle {
  font-size: 14px;
  font-weight: 500;
  color: #a80000;
}
</style>
