import https from '@/router/https'
//获取地址
function submitOrder(param) {
  return https.fetchPost('/mallapp/orderinfo', param);
}
// 支付宝支付
function submitAlipay(param) {
  return https.fetchPost('/mallapp/pay/alipayCart', param);
}
// 微信支付
function submitWxpay(param) {
  return https.fetchPost('/mallapp/orderinfo/unifiedOrder', param);
}
function wxPay(param) {
  return https.fetchPost('/mallapp/orderinfo/unifiedOrder', param);
}

export {
  submitOrder,
  submitAlipay,
  submitWxpay,
  wxPay
}