import { fetchGet, fetchPost,fetchPut } from '../../router/https'
// 最商品详情
function geItemDetail(data) {
    return fetchGet("/mallapp/goodsspu/"+ data)
}
// 加入购物车
function addCart(data) {
    return fetchPost("/mallapp/shoppingcart", data)
}
// 领取商品优惠券
function getCouponList(data) {
    return fetchGet("/mallapp/couponinfo/page", data)
}

// 修改购物车
function updateshoppingcart(data) {
    return fetchPut("/mallapp/shoppingcart", data)
}
export {
    geItemDetail,
    addCart,
    getCouponList,
    updateshoppingcart
} 